import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { bannerList, maleAvatar } from "../../../assets/img";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import {
  usePushNotifiAddMutation,
  useLazyUserviewQuery,
  useLazyGetUserHistoryQuery,
  useLazyGetUserHistoryFilterQuery,
} from "../../../constants/redux/api/api";
import { Row, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import swal from "sweetalert";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const UserProfile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // RTK
  const [pushNotifiAddApi] = usePushNotifiAddMutation();
  const [userViewApi] = useLazyUserviewQuery();
  const [userHistoryApi] = useLazyGetUserHistoryQuery();
  const [userHistoryFilterApi] = useLazyGetUserHistoryFilterQuery();

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },

      {
        Header: "Ride ID",
        accessor: "id",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Ride Date And Time",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <p>{moment(value).format("DD/MM/YYYY hh:mm A")}</p>;
        },
      },
      {
        Header: "driver Name",
        accessor: "driver.name",
        Cell: ({ value, row }) => {
          return (
            <p
              style={{
                cursor: "pointer",
                "text-decoration-line": "underline",
                background: "none",
                color: "var(--bs-link-color)",
              }}
              onClick={() =>
                navigate("/driver/view-profile", {
                  state: { id: row.original.driver.id },
                })
              }
            >
              {value}
            </p>
          );
        },
      },
      {
        Header: "From",
        accessor: "ride_detail.pickup_address",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "To",
        accessor: "ride_detail.drop_address",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Distance",
        accessor: "distance",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Cost",
        accessor: "final_amount",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Ride Duration",
        accessor: "total_hrs",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              className={
                value == 3
                  ? "p-2 py-1 comn-btn active-deact-btn bg-primary"
                  : value == 4
                  ? "p-2 py-1 comn-btn active-deact-btn bg-primary"
                  : value == 5
                  ? "p-2 py-1 comn-btn active-deact-btn bg-primary"
                  : value == 6
                  ? "p-2 py-1 comn-btn active-deact-btn bg-primary"
                  : value == 7
                  ? "p-2 py-1 comn-btn active-deact-btn bg-primary"
                  : value == 8
                  ? "p-2 py-1 comn-btn active-deact-btn bg-primary"
                  : value == 9
                  ? "p-2 py-1 comn-btn active-deact-btn bg-primary"
                  : value == 10
                  ? "p-2 py-1 comn-btn active-deact-btn bg-success"
                  : value == 11
                  ? "p-2 py-1 comn-btn active-deact-btn bg-primary"
                  : value == 12
                  ? "p-2 py-1 comn-btn active-deact-btn bg-success"
                  : value == 1
                  ? "p-2 py-1 comn-btn active-deact-btn bg-primary"
                  : value == 2
                  ? "p-2 py-1 comn-btn active-deact-btn bg-danger"
                  : "p-2 py-1 comn-btn active-deact-btn bg-danger"
              }
            >
              {value == 3
                ? "Progress"
                : value == 4
                ? "Progress"
                : value == 5
                ? "Progress"
                : value == 6
                ? "Progress"
                : value == 7
                ? "Progress"
                : value == 8
                ? "Progress"
                : value == 9
                ? "Progress"
                : value == 10
                ? "Completed"
                : value == 11
                ? "Progress"
                : value == 12
                ? "Completed"
                : value == 1
                ? "Progress"
                : value == 2
                ? "Cancelled"
                : "Rejected"}
            </button>
          );
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button
                className="comn-btn bg-primary edit-btn-svg"
                onClick={() =>
                  navigate("/booking/view-booking", {
                    state: { id: row.original.id, index: row.index + 1 },
                  })
                }
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const location = useLocation();
  const Id = location?.state?.id;

  // console.log(location);
  const [list, setList] = useState([]);
  const [res, setRes] = useState([]);
  const [add, setAdd] = useState([]);
  const [number, setNumber] = useState([]);
  const [num, setNum] = useState([]);

  const view = () => {
    userViewApi(Id).then((responce) => {
      // console.log("res", responce);
      setRes(responce?.data);
      let temp = [];
      let num = [];
      let temps = responce?.user?.user_addresses?.filter((i) => i.type == 1);
      if (temps) {
        temp.push(temps[0]?.address);
        temp.push(temps[0]?.pin_code);
        setAdd(temp);
      }

      if (responce?.user?.user_emergency_contacts?.length > 0) {
        responce?.user?.user_emergency_contacts.map((item) => {
          num.push(item?.mobile);
        });
        setNum(num[0]);

        setNumber(num.slice(1, num?.length));
      }
    });
  };

  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  const userHistory = () => {
    let params = `?start_date=${from}&end_date=${to}`;
    if (from && to) {
      userHistoryFilterApi({ id: Id, params: params })
        .unwrap()
        .then((res) => {
          setList(res?.user_ride_history);
        });
    } else if (!from && !to) {
      userHistoryApi(Id)
        .unwrap()
        .then((res) => {
          setList(res?.user_ride_history);
        });
    }
  };

  useEffect(() => {
    if (Id) {
      view();
      userHistory();
    }
  }, [from, to]);

  // PUSH NOTIFICATION

  const [image, setImage] = useState("");
  const [imagePre, setImagePre] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [btnEnable, setBtnEnable] = useState(false);

  const [input, setInput] = useState([]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setInput((pre) => ({ ...pre, [name]: value }));
  };

  const addNotify = () => {
    let data = {
      title: title,
      description: description,
      image: image,
      type: 3,
      user_id: Id,
    };
    let formdata = new FormData();
    formdata.append("title", title);
    formdata.append("description", description);
    formdata.append("image", image);
    formdata.append("type", 3);
    formdata.append("user_id", Id);
    if (title?.length !== 0 && description?.length !== 0) {
      setBtnEnable(true);
      pushNotifiAddApi(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            setAddModal(false);
            setImage("");
            setImagePre("");
            setDescription("");
            setTitle("");
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            setAddModal(false);
            setBtnEnable(false);
          } else {
            setBtnEnable(false);
          }
        });
    } else {
      toast("Please Fill All Details", {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose: 1500,
      });
    }
  };

  // console.log('res', res);

  return (
    <aside>
      <TopHeader type="user-profile" />
      <article className="p-3">
        <article>
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <div className="flex-ac-jb">
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                >
                  {res?.user?.name}
                </p>
                <img
                  className="avatar-img"
                  src={res?.user?.img_url || maleAvatar}
                />
              </div>
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Name
                      </label>
                      <input
                        id="example-if-email"
                        name="name"
                        placeholder="User Name"
                        className="bg-transparent"
                        type="text"
                        value={res?.user?.name}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User ID
                      </label>
                      <input
                        id="example-if-email"
                        name="name"
                        placeholder="User id"
                        className="bg-transparent"
                        type="text"
                        value={res?.user?.id ? "#00" + res?.user?.id : ""}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Phone Number
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Phone Number"
                        className="bg-transparent"
                        type="text"
                        value={res?.user?.mobile}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Primary Number
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Phone Number"
                        className="bg-transparent"
                        type="text"
                        value={res?.user?.primary_mobile}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Email
                      </label>
                      <input
                        type="email"
                        id="example-if-email"
                        placeholder="Email"
                        className="bg-transparent"
                        value={res?.user?.email}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="form-group col-12 col-sm-12 col-lg-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Emergency Contact
                      </label>

                      <p className="address-request textarea-height" disabled>
                        {number.toString()}
                      </p>
                    </div>
                  </div>
                </div> */}
                {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        id="example-if-email"
                        placeholder="Email"
                        className="bg-transparent"
                        value="2022-03-03"
                        disabled
                      />
                    </div>
                  </div>
                </div> */}
                <div className="form-group col-12  ps-0">
                  <div className="form-check ps-0">
                    <div className="d-flex justify-content-end col-sm-12 modal-label-input">
                      <button
                        type="button"
                        className="comn-btn bg-success-gradient px-3 py-2 me-2"
                        onClick={() => setAddModal(true)}
                      >
                        Add Notification
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        SOS
                      </label>
                      <input
                        placeholder="address"
                        type="text"
                        name="address"
                        className="bg-transparent"
                        value="983273672"
                        disabled
                      />
                    </div>
                  </div>
                </div> */}

                <div className="form-group col-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      {res?.user?.user_addresses?.length > 0 &&
                        res?.user?.user_addresses.map((item) => {
                          return (
                            <div>
                              <label className="text-primary-dark" for="">
                                {item?.type == 1
                                  ? "Home Address"
                                  : item?.type == 2
                                  ? "Office Address"
                                  : item?.type == 3
                                  ? "Other address"
                                  : ""}
                              </label>
                              <p
                                className="address-request textarea-height"
                                disabled
                              >
                                {item?.address}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                {/* <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        City
                      </label>
                      <input
                        type="text"
                        name="city"
                        className="bg-transparent"
                        placeholder="city"
                        value={"chennai"}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        State
                      </label>
                      <input
                        placeholder="State"
                        type="text"
                        name="state"
                        className="bg-transparent"
                        value={"TN"}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Country
                      </label>
                      <input
                        placeholder="Country"
                        type="text"
                        name="country"
                        className="bg-transparent"
                        value={"INDIA"}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Pin Code
                      </label>
                      <input
                        placeholder="Pin Code"
                        type="text"
                        name="pin_code"
                        className="bg-transparent"
                        value={"756454"}
                        disabled
                      />
                    </div>
                  </div>
                </div> */}
              </Form>
            </div>
          </div>
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <Form className="d-flex flex-wrap">
                {/* <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        UPI ID
                      </label>
                      <input
                        placeholder="upi id"
                        type="text"
                        name="address"
                        className="bg-transparent"
                        value={res?.user?.upi_id}
                        disabled
                      />
                    </div>
                  </div>
                </div> */}
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Total Referral's
                      </label>
                      <input
                        placeholder="refferrals"
                        type="text"
                        name="pin_code"
                        className="bg-transparent"
                        value={res?.total_referals}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Total Rewards
                      </label>
                      <input
                        placeholder="rewards"
                        type="text"
                        name="pin_code"
                        className="bg-transparent"
                        value={res?.total_rewards}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Rewards Claimed
                      </label>
                      <input
                        placeholder="Claimed"
                        type="text"
                        name="pin_code"
                        className="bg-transparent"
                        value={res?.total_reward_claimed}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Subscription Expiry Date
                      </label>
                      <input
                        placeholder="Coupon"
                        type="date"
                        name="pin_code"
                        className="bg-transparent"
                        value={"2023-05-22"}
                        // disabled
                      />
                    </div>
                  </div>
                </div> */}
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Total Rides
                      </label>
                      <input
                        placeholder="rides"
                        type="text"
                        name="pin_code"
                        className="bg-transparent"
                        value={res?.total_rides}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Total Amount Spent
                      </label>
                      <input
                        placeholder="amount"
                        type="text"
                        name="pin_code"
                        className="bg-transparent"
                        value={res?.total_amt_spent}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Total Cancelled Rides
                      </label>
                      <input
                        placeholder="Cancelled"
                        type="text"
                        name="pin_code"
                        className="bg-transparent"
                        value={res?.cancel_rides}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Total Missed Rides
                      </label>
                      <input
                        placeholder="Missed"
                        type="text"
                        name="pin_code"
                        className="bg-transparent"
                        value={res?.missed_rides}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Total Rating
                      </label>
                      {res?.total_ratings == 1 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;</p>
                      ) : res?.total_ratings == 2 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;&#9733;</p>
                      ) : res?.total_ratings == 3 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733;
                        </p>
                      ) : res?.total_ratings == 4 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : res?.total_ratings == 5 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Coupon Add
                      </label>
                      <select name="" id="">
                        <option>Choose ...</option>
                        <option value="coupon1">Coupon1</option>
                        <option value="coupon2">Coupon2</option>
                      </select>
                      <div className="d-flex flex-row-reverse">
                        <button className="comn-btn bg-primary px-3 py-2 me-2">
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </Form>
            </div>
          </div>

          {/* <div className="p-2 bg-white rounded-2">
            <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
              <Col md={3} xs={6} className="px-2 my-2">
                <span>From Date</span>
                <input
                  type="date"
                  className="form-control"
                  onChange={startDate}
                  value={from}
                />
              </Col>
              <Col md={3} xs={6} className="px-2 my-2">
                <span>To Date</span>
                <input
                  type="date"
                  className="form-control"
                  onChange={ToDate}
                  value={to}
                />
              </Col>
              <Col md={4} xs={6} className="px-2 my-2">
                <Form className="search-input me-2">
                  <input
                    onChange={(e) => setSearchArr(e.target.value)}
                    placeholder="search..."
                    type="search"
                    className="bg-transparent rounded-1 px-2 py-1 form-control"
                  />
                </Form>
              </Col>
            </div>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Ride ID</th>
                  <th>Ride Date</th>
                  <th>Driver Name</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Distance</th>
                  <th>Cost</th>
                  {/* <th>Average Speed</th> 
                  <th>Ride Duration</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((list, index) => (
                  <tr key={index}>
                    <td>
                      {index == 9
                        ? last
                        : counter
                        ? `${counter}${index + 1}`
                        : index + 1}
                    </td>
                    <td>{"#00" + list?.id}</td>
                    <td>{moment(list?.updated_at).format("DD/MM/YYYY")}</td>
                    <td
                      style={{
                        cursor: "pointer",
                        "text-decoration-line": "underline",
                        background: "none",
                        color: "var(--bs-link-color)",
                      }}
                      onClick={() =>
                        navigate("/driver/view-profile", {
                          state: { id: list?.id },
                        })
                      }
                    >
                      {list?.driver?.name}
                    </td>
                    <td>{list?.ride_detail?.pickup_address}</td>
                    <td>{list?.ride_detail?.drop_address}</td>
                    <td>{list?.distance + " Kms"}</td>
                    <td>{list?.final_amount}</td>
                    {/* <td>{list?.avg_speed}</td> 
                    <td>{list?.total_hrs}</td>
                    <td
                      className={
                        list.status == 1
                          ? "text-warning"
                          : list.status == 3
                          ? "text-warning"
                          : list.status == 4
                          ? "text-warning"
                          : list.status == 5
                          ? "text-warning"
                          : list.status == 6
                          ? "text-warning"
                          : list.status == 7
                          ? "text-warning"
                          : list.status == 8
                          ? "text-warning"
                          : list.status == 9
                          ? "text-warning"
                          : list.status == 10
                          ? "text-success"
                          : list.status == 11
                          ? "text-warning"
                          : list.status == 12
                          ? "text-success"
                          : list?.status == 2
                          ? "text-danger"
                          : "text-danger"
                      }
                    >
                      {list.status == 1
                        ? "Progress"
                        : list.status == 3
                        ? "Progress"
                        : list.status == 4
                        ? "Progress"
                        : list.status == 5
                        ? "Progress"
                        : list.status == 6
                        ? "Progress"
                        : list.status == 7
                        ? "Progress"
                        : list.status == 8
                        ? "Progress"
                        : list.status == 9
                        ? "Progress"
                        : list.status == 10
                        ? "Completed"
                        : list.status == 11
                        ? "Progress"
                        : list.status == 12
                        ? "Completed"
                        : list?.status == 2
                        ? "Cancelled"
                        : "Rejected"}
                    </td>
                    <td className="flex-ac text-center">
                      <button
                        onClick={() =>
                          navigate("/booking/view-booking", {
                            state: { id: list?.id, name: res?.user?.name },
                          })
                        }
                        className="comn-btn bg-primary edit-btn-svg"
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
              <ReactPaginate
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div> */}
          <div className="p-2 bg-white rounded-2">
            <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
              <Col md={3} xs={6} className="px-2 my-2">
                <span>From Date</span>
                <input
                  type="date"
                  className="form-control"
                  onChange={startDate}
                  value={from}
                />
              </Col>
              <Col md={3} xs={6} className="px-2 my-2">
                <span>To Date</span>
                <input
                  type="date"
                  className="form-control"
                  onChange={ToDate}
                  value={to}
                />
              </Col>
            </div>
            <Table columns={columns} data={list} className="" />
          </div>
        </article>
      </article>
      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add PushNotification</h4>
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Tittle
            </label>
            <input
              type="title"
              id="example-if-email"
              name="title"
              placeholder="title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group col-12 col-sm-12 ps-0">
            <div className="form-check ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  Description
                </label>
                <textarea
                  placeholder="Description"
                  type="text"
                  className="bg-transparent"
                  cols="30"
                  rows="10"
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Image
            </label>
            <input
              type="file"
              id="example-if-email"
              name="img"
              placeholder="img"
              onChange={(e) => {
                setImage(e.target.files[0]);
                setImagePre(URL.createObjectURL(e.target.files[0]));
              }}
            />
            {imagePre ? (
              <div className="w-90 prev-Image">
                <img className="w-100" src={imagePre} />
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => setAddModal(false)}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={addNotify}
            disabled={btnEnable == true ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default UserProfile;
