import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { json, Link } from "react-router-dom";
import { bannerList, maleAvatar ,imgNotava} from "../../../assets/img";
import Pagination from "react-bootstrap/Pagination";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import DriverHistorys from "./driverHistorys";
import { toast } from "react-toastify";
import axios from "axios";
import {
  useLazyDriverViewQuery,
  usePushNotifiAddMutation,
  useDriverSubscriptionMutation,
} from "../../../constants/redux/api/api";
import { MAPS_API_KEY } from "../../../constants/redux/api/constants";

const DriverProfile = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const Id = location?.state?.id;

  console.log(Id);

  const [detail, setDetails] = useState([]);
  const [aadh, setAadh] = useState();
  const [lic, setLic] = useState();
  const [vehic, setVechic] = useState();
  const [rcNo, setRc] = useState();
  const [aadhars, setAadhars] = useState();
  const [insuran, setInsuran] = useState();
  const [licNo, setLicNo] = useState();

  const [userData, setUserData] = useState();
  const [licenData, setLicenData] = useState();
  const [VehicleData, seVehicleData] = useState();
  const [aadhaarAdd, setAadhaarAdd] = useState();
  const [respon, setRespon] = useState();
  const [endDete, setEndDate] = useState();
  const [latLong, setLatLong] = useState();
  const [modelimgs, setModelImg] = useState(false);
  const [images, setImages] = useState("");
  //RTK

  const [driveApi] = useLazyDriverViewQuery();
  const [pushNotifiAddApi] = usePushNotifiAddMutation();
  const [driverSubscriptionApi] = useDriverSubscriptionMutation();

  // DRIVER LIST
  const viewDriver = () => {
    driveApi(Id)
      .unwrap()
      .then((res) => {
        console.log(res);
        setDetails(res?.driver);
        setRespon(res);
        setEndDate(res?.driver?.subscription_end_date);
        let aadhaar = res?.driver?.driver_proofs?.filter((i) => i?.type == 5);
        let license = res?.driver?.driver_proofs?.filter((i) => i?.type == 4);
        let rcNum = res?.driver?.driver_proofs?.filter((i) => i?.type == 2);
        let vehicNum = res?.driver?.driver_proofs?.filter((i) => i?.type == 1);
        let insNum = res?.driver?.driver_proofs?.filter((i) => i?.type == 3);

        if (aadhaar) {
          let aadhaars = aadhaar[0]?.number.slice(-4);
          setAadh(aadhaars);

          let temp = JSON.parse(aadhaar[0].details);
          setUserData(temp);

          let add1 = [
            temp?.address?.house,
            temp?.address?.street,
            temp?.address?.po,
            temp?.address?.state,
            temp?.zip,
          ];

          setAadhaarAdd(add1);
          setAadhars(aadhaar[0]);
        }

        if (license) {
          let licen = license[0]?.number.slice(-4);
          setLic(licen);
          let temp1 = JSON.parse(license[0].details);
          setLicenData(temp1);
          setLicNo(license[0]);
        }

        if (vehicNum) {
          let temp2 = JSON.parse(vehicNum[0].details);
          seVehicleData(temp2);
          setVechic(vehicNum[0]);
        }

        if (rcNum) {
          setRc(rcNum[0]);
        }

        if (insNum) {
          setInsuran(insNum[0]);
        }

        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${res?.driver?.latitude},${res?.driver?.longitude}&key=${MAPS_API_KEY}`
          )
          .then((response) => {
            const address = response?.data?.results[0];

            // console.log("res", address);

            setLatLong(address);
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  useEffect(() => {
    if (Id) {
      viewDriver();
    }
  }, []);

  // PUSH NOTIFICATION

  const [image, setImage] = useState("");
  const [imagePre, setImagePre] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [btnEnable, setBtnEnable] = useState(false);

  const [input, setInput] = useState([]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setInput((pre) => ({ ...pre, [name]: value }));
  };

  const addNotify = () => {
    let data = {
      title: title,
      description: description,
      image: image,
      type: 3,
      user_id: Id,
    };
    let formdata = new FormData();
    formdata.append("title", title);
    formdata.append("description", description);
    formdata.append("image", image);
    formdata.append("type", 3);
    formdata.append("user_id", Id);
    if (title?.length !== 0 && description?.length !== 0) {
      setBtnEnable(true);
      pushNotifiAddApi(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            setAddModal(false);
            setImage("");
            setImagePre("");
            setDescription("");
            setTitle("");
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            setAddModal(false);
            setBtnEnable(false);
          } else {
            setBtnEnable(false);
          }
        });
    } else {
      toast("Please Fill All Details", {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose: 1500,
      });
    }
  };

  const modelimg = (img) => {
    setImages(img)
    setModelImg(true)
  };

  // SUBSCRIPTION DATE CHANGE

  const changeDate = () => {
    let formdata = new FormData();
    formdata.append("driver_id", Id);
    formdata.append("subscription_end_date", endDete);
    setBtnEnable(true);
    driverSubscriptionApi(formdata)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setBtnEnable(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          viewDriver();
        } else {
          setBtnEnable(false);
        }
      });
  };

  // console.log(VehicleData);

  return (
    <aside>
      <TopHeader type="view-profile" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Detail-View" title="Profile">
            <article>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="flex-ac-jb">
                    <p
                      className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                      for=""
                    >
                      {detail?.name}
                    </p>
                    <img
                      className="avatar-img"
                      src={detail?.img_url || maleAvatar}
                    />
                  </div>
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Driver Name
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="driver Name"
                            className="bg-transparent"
                            type="text"
                            value={detail?.name}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Driver ID
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="driver id"
                            className="bg-transparent"
                            type="text"
                            value={detail?.id ? "#00" + detail?.id : ""}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Phone Number
                          </label>
                          <input
                            id="example-if-email"
                            name="Phone_Number"
                            placeholder="Phone Number"
                            className="bg-transparent"
                            type="text"
                            value={detail?.mobile}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Emergency Contact
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="driver Name"
                            className="bg-transparent"
                            type="text"
                            value="8838726354"
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Email
                          </label>
                          <input
                            type="email"
                            id="example-if-email"
                            placeholder="Email"
                            className="bg-transparent"
                            value={detail?.driver_detail?.email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Gender
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="Gender"
                            className="bg-transparent"
                            type="text"
                            value={
                              userData?.gender
                                ? userData?.gender.toLowerCase() == "m"
                                  ? "Male"
                                  : "Female"
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Aadhaar Number (Masked - only last four digits)
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={aadh ? "*********" + aadh : ""}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            License Number (Masked - Only Last four digits)
                          </label>
                          <input
                            type="text"
                            name="license-number"
                            id="example-if-email"
                            placeholder="license number"
                            className="bg-transparent"
                            value={lic ? "************" + lic : ""}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Date of Birth
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="Date of Birth"
                            className="bg-transparent"
                            type="text"
                            value={
                              userData?.dob
                                ? moment(userData?.dob).format("DD/MM/YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            DOB Matching
                          </label>
                        </div>
                      </div>

                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="option1"
                          checked
                        />
                        <label class="form-check-label" for="inlineCheckbox1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox2"
                          value="option2"
                        />
                        <label class="form-check-label" for="inlineCheckbox2">
                          No
                        </label>
                      </div>
                    </div> */}

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Number
                          </label>
                          <input
                            placeholder="vehicle number"
                            className="bg-transparent"
                            type="text"
                            value={vehic?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            RC Number
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="rc number"
                            className="bg-transparent"
                            value={rcNo?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            UPI ID
                          </label>
                          <input
                            placeholder="UPI ID"
                            className="bg-transparent"
                            type="text"
                            value={detail?.driver_detail?.upi_id}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12  ps-0">
                      <div className="form-check ps-0">
                        <div className="d-flex justify-content-end col-sm-12 modal-label-input">
                          <button
                            type="button"
                            className="comn-btn bg-success-gradient px-3 py-2 me-2"
                            onClick={() => setAddModal(true)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Notification"
                          >
                            Add Notification
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address
                          </label>
                          <input
                            placeholder="address"
                            type="text"
                            name="address"
                            className="bg-transparent"
                            value="street"
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            City
                          </label>
                          <input
                            type="text"
                            name="city"
                            className="bg-transparent"
                            placeholder="city"
                            value={"chennai"}
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            State
                          </label>
                          <input
                            placeholder="State"
                            type="text"
                            name="state"
                            className="bg-transparent"
                            value={"TN"}
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Country
                          </label>
                          <input
                            placeholder="Country"
                            type="text"
                            name="country"
                            className="bg-transparent"
                            value={"INDIA"}
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Pin Code
                          </label>
                          <input
                            placeholder="Pin Code"
                            type="text"
                            name="pin_code"
                            className="bg-transparent"
                            value={"756454"}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group col-12 col-sm-12 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address
                          </label>
                          <p
                            className="address-request textarea-height"
                            disabled
                          >
                            {detail?.driver_detail?.address}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-12 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Live Location
                          </label>
                          <p
                            className="address-request textarea-height"
                            disabled
                          >
                            {latLong?.formatted_address}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Installation Date
                          </label>
                          <input
                            placeholder="Installation Date"
                            type="date"
                            name="current_plan"
                            className="bg-transparent"
                            value={
                              detail?.created_at
                                ? moment(detail?.created_at).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Document Submission Date
                          </label>
                          <input
                            placeholder="Submission date"
                            type="date"
                            name="date"
                            className="bg-transparent"
                            value={
                              rcNo?.created_at
                                ? moment(rcNo?.created_at).format("YYYY-MM-DD")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Rejection Date and Reason
                          </label>
                          <input
                            placeholder="Rejection Date"
                            type="text"
                            name="current_tax"
                            className="bg-transparent"
                            value={"-"}
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Approved Date
                          </label>
                          <input
                            placeholder="Approved Date"
                            type="date"
                            name="current_tax"
                            className="bg-transparent"
                            value={
                              detail?.updated_at
                                ? moment(detail?.accepted_rejected_date).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Current Subscription Plan
                          </label>
                          <input
                            placeholder="Subscription plan"
                            type="text"
                            name="current_plan"
                            className="bg-transparent"
                            value={
                              respon?.driver_subsctiption?.subscription?.name
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Subscription Expiry Date
                          </label>
                          {/* <input
                            placeholder="Expiry Date"
                            type="date"
                            name="current_plan"
                            className="bg-transparent"
                            value={detail?.subscription_end_date}
                            disabled
                          /> */}
                          <div class="input-group mb-3 ">
                            <input
                              type="date"
                              class="form-control"
                              placeholder="Expiry Date"
                              aria-label="Expiry Date"
                              aria-describedby="basic-addon2"
                              onChange={(e) => setEndDate(e.target.value)}
                              value={endDete}
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary "
                                style={{ zIndex: "auto" }}
                                type="button"
                                onClick={changeDate}
                                disabled={btnEnable ? true : false}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Change Date"
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Referral's
                          </label>
                          <input
                            placeholder="Referral "
                            type="text"
                            name="current_plan"
                            className="bg-transparent"
                            value={respon?.total_referrals}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Tax Paid
                          </label>
                          <input
                            placeholder="Paid"
                            type="text"
                            name="date"
                            className="bg-transparent"
                            value={respon?.total_tax_paid}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Outstanding Tax Amount
                          </label>
                          <input
                            placeholder="Tax"
                            type="text"
                            name="date"
                            className="bg-transparent"
                            value={respon?.tax_percentage}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Amount Earned
                            {/* (without Tax) */}
                          </label>
                          <input
                            placeholder="Earned "
                            type="text"
                            name="current_tax"
                            className="bg-transparent"
                            value={respon?.total_earned_amt}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Rides
                          </label>
                          <input
                            placeholder="Rides"
                            type="text"
                            name="date"
                            className="bg-transparent"
                            value={respon?.total_rides}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Log In Hours
                          </label>
                          <input
                            placeholder="Hours"
                            type="text"
                            name="date"
                            className="bg-transparent"
                            value={respon?.total_login_hrs}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Cancelled Rides
                          </label>
                          <input
                            placeholder="Cancelled"
                            type="text"
                            name="Cancelled"
                            className="bg-transparent"
                            value={respon?.cancel_rides}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Missed Rides
                          </label>
                          <input
                            placeholder="Missed "
                            type="text"
                            name="current_tax"
                            className="bg-transparent"
                            value={"10"}
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Rejected Rides
                          </label>
                          <input
                            placeholder="Rejected "
                            type="text"
                            name="current_tax"
                            className="bg-transparent"
                            value={"14"}
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Total Rating
                          </label>
                          {respon?.total_ratings == 1 ? (
                            <p style={{ color: "#FBC740", marginTop: "5px" }}>
                              &#9733;
                            </p>
                          ) : respon?.total_ratings == 2 ? (
                            <p style={{ color: "#FBC740", marginTop: "5px" }}>
                              &#9733;&#9733;
                            </p>
                          ) : respon?.total_ratings == 3 ? (
                            <p style={{ color: "#FBC740", marginTop: "5px" }}>
                              &#9733; &#9733; &#9733;
                            </p>
                          ) : respon?.total_ratings == 4 ? (
                            <p style={{ color: "#FBC740", marginTop: "5px" }}>
                              &#9733; &#9733; &#9733; &#9733;
                            </p>
                          ) : respon?.total_ratings == 5 ? (
                            <p style={{ color: "#FBC740", marginTop: "5px" }}>
                              &#9733; &#9733; &#9733; &#9733; &#9733;
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <DriverHistorys Id={Id} />
            </article>
          </Tab>
          <Tab eventKey="profile" title="Document">
            <article>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-11 col-sm-11 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Aadhaar
                          </label>
                          <div onClick={() =>modelimg(aadhars?.img_url)} role="button">
                            <img src={aadhars?.img_url||imgNotava} width={150} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Aadhaar Number
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={aadhars?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            DOB in Aadhaar
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="DOB in Aadhaar"
                            className="bg-transparent"
                            value={
                              userData?.dob
                                ? moment(userData?.dob).format("DD/MM/YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address In Aadhaar
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value="Address"
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Aadhaar Registered Number
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value="9878564545"
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Father Name
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value="Father Name"
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group col-12 col-sm-12 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address
                          </label>
                          <p
                            className="address-request textarea-height"
                            disabled
                          >
                            {aadhaarAdd?.toString()}
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </Form>
                </div>
              </div>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-11 col-sm-11 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            License
                          </label>
                          <div onClick={() =>modelimg(licNo?.img_url)} role="button">
                            <img src={licNo?.img_url||imgNotava} width={150} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            License Number
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={licNo?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            DOB in License
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="DOB in Aadhaar"
                            className="bg-transparent"
                            value={
                              licenData?.dob
                                ? moment(licenData?.dob).format("DD/MM/YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            DOE of License
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={
                              licenData?.doe
                                ? moment(licenData?.doe).format("DD/MM/YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            DOI of License
                          </label>
                          <input
                            placeholder="aadhaar number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={
                              licenData?.doi
                                ? moment(licenData?.doi).format("DD/MM/YYYY")
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12  ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address in License
                          </label>
                          <p
                            className="address-request textarea-height"
                            disabled
                          >
                            {licenData?.temporary_address}
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </Form>
                </div>
              </div>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-11 col-sm-11 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle
                          </label>
                          <div onClick={() =>modelimg(vehic?.img_url)} role="button">
                            <img src={vehic?.img_url||imgNotava} width={150} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Number
                          </label>
                          <input
                            placeholder="Number "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={vehic?.number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            RC Status
                          </label>
                          <input
                            placeholder="Status "
                            type="text"
                            name="DOB in Aadhaar"
                            className="bg-transparent"
                            value={VehicleData?.rc_status}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Model
                          </label>
                          <input
                            placeholder="Model "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={VehicleData?.maker_model}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Manufacturer
                          </label>
                          <input
                            placeholder="Manufacturer "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={VehicleData?.maker_description}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Insurance UpTo
                          </label>
                          <input
                            placeholder="Insurance "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={
                              VehicleData?.insurance_upto
                                ? moment(VehicleData?.insurance_upto).format(
                                    "DD/MM/YYYY"
                                  )
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Owner Name
                          </label>
                          <input
                            placeholder="Owner "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={VehicleData?.owner_name}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Father Name
                          </label>
                          <input
                            placeholder="Father "
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={VehicleData?.father_name}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Mobile Number
                          </label>
                          <input
                            placeholder="number"
                            type="text"
                            name="aadhaar number"
                            className="bg-transparent"
                            value={VehicleData?.mobile_number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12  ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address
                          </label>
                          <p
                            className="address-request textarea-height"
                            disabled
                          >
                            {VehicleData?.present_address}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              {/* <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            RC
                          </label>
                          <div>
                            <img
                              src="https://static.toiimg.com/thumb/resizemode-4,msid-92497864,imgsize-68152,width-720/92497864.jpg"
                              width={150}
                            />
                          </div>
                          <small>820198367235261</small>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div> */}
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            UPI ID & Bar Code
                          </label>
                          <div onClick={() =>modelimg(detail?.driver_detail?.img_url)} role="button">
                            <img
                              src={detail?.driver_detail?.img_url || imgNotava}
                              width={130}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="form-group col-12 col-sm-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          UPI ID
                        </label>
                        <input
                          placeholder="UPI ID"
                          type="text"
                          name="aadhaar number"
                          className="bg-transparent"
                          value={detail?.driver_detail?.upi_id}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </Tab>
        </Tabs>
      </article>
      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add PushNotification</h4>
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Tittle
            </label>
            <input
              type="title"
              id="example-if-email"
              name="title"
              placeholder="title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group col-12 col-sm-12 ps-0">
            <div className="form-check ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  Description
                </label>
                <textarea
                  placeholder="Description"
                  type="text"
                  className="bg-transparent"
                  cols="30"
                  rows="10"
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Image
            </label>
            <input
              type="file"
              id="example-if-email"
              name="img"
              placeholder="img"
              onChange={(e) => {
                setImage(e.target.files[0]);
                setImagePre(URL.createObjectURL(e.target.files[0]));
              }}
            />
            {imagePre ? (
              <div className="w-90 prev-Image">
                <img className="w-100" src={imagePre} />
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => setAddModal(false)}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={addNotify}
            disabled={btnEnable == true ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
      <Modal
        className="table-modal"
        show={modelimgs}
        onHide={() => setModelImg(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <img src={images || imgNotava} width={480}  height={250}/>
        </div>
      </Modal>
    </aside>
  );
};

export default DriverProfile;
