import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  adminIcon,
  AppointmentIcon,
  BannerIcon,
  categoryIcon,
  dashbaordIcon,
  doctorIcon,
  FaqIcon,
  HelpIcon,
  hospitalIcon,
  Logout,
  NotifIcon,
  patientIcon,
  PrivacyIcon,
  RequestIcon,
  roleIcon,
  TermsCondiIcon,
} from "../assets/img";
import { ToastContainer, toast } from "react-toastify";
import { RxDashboard } from "react-icons/rx";
import { GiNotebook } from "react-icons/gi";
import {
  MdOutlineSubscriptions,
  MdOutlinePayment,
  MdOutlineSettings,
  MdOutlineNotificationAdd,
  MdOutlineStarRate,
  MdOutlineSupportAgent,
} from "react-icons/md";
import { AiOutlineSchedule } from "react-icons/ai";
import { CgList } from "react-icons/cg";
import { VscReferences } from "react-icons/vsc";
import {
  RiAdminLine,
  RiUserSettingsLine,
  RiMoneyCnyCircleLine,
  RiLogoutCircleRLine,
} from "react-icons/ri";
import {
  TbUserPlus,
  TbUserCheck,
  TbUsers,
  TbFileTime,
  TbReport,
} from "react-icons/tb";
import { useLazyLogoutQuery } from "../constants/redux/api/api";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("loca", location);
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const permissons = JSON.parse(localStorage.getItem("role"));

  const [logoutApi] = useLazyLogoutQuery();

  // LOGOUT

  const Log = () => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          // window.location.reload();
          window.location.reload();
        }, 800);
        logoutApi()
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              setTimeout(() => {
                // window.location.reload();
                window.location.reload();
                localStorage.clear();
              }, 800);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    });
  };

  return (
    <nav>
      <ul>
        {/* {permissons?.includes("CAN-LIST-DASHBOARD") ? ( */}
        <li>
          <Link
            type=""
            className={
              splitLocation[1] === "" ? "menu-list active" : "menu-list"
            }
            to="/"
          >
            {/* <img className="menu-icon" src={dashbaordIcon} /> */}
            <RxDashboard />
            Dashboard
          </Link>
        </li>
        {/* //  ) : null} */}
        {permissons?.includes("CAN-LIST-ADMINS") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "admin" ? "menu-list active" : "menu-list"
              }
              to="/admin"
            >
              {/* <img className="menu-icon" src={adminIcon} /> */}
              <RiAdminLine />
              Admins
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-ROLES") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "role" ? "menu-list active" : "menu-list"
              }
              to="/role"
            >
              {/* <img className="menu-icon" src={roleIcon} /> */}
              <RiUserSettingsLine />
              Roles
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-VEHICLE TYPES") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "vehicle-category"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/vehicle-category"
            >
              {/* <img className="menu-icon" src={roleIcon} /> */}
              <RiUserSettingsLine />
              Vehicle Type
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-DRIVERS") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "driver-request"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/driver-request"
            >
              {/* <img className="menu-icon" src={adminIcon} /> */}
              <TbUserPlus />
              Driver Request
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-DRIVERS") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "driver" ? "menu-list active" : "menu-list"
              }
              to="/driver"
            >
              {/* <img className="menu-icon" src={adminIcon} /> */}
              <TbUserCheck />
              Driver
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-USERS") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "user" ? "menu-list active" : "menu-list"
              }
              to="/user"
            >
              {/* <img className="menu-icon" src={adminIcon} /> */}
              <TbUsers />
              Users
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-BOOKING HISTORY") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "booking"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/booking"
            >
              {/* <img className="menu-icon" src={AppointmentIcon} /> */}
              <GiNotebook />
              Booking
            </Link>
          </li>
        ) : null}
        {/* {permissons?.includes("CAN-LIST-REWARDS") ? ( */}
        {/* <li>
          <Link
            className={
              splitLocation[1] === "rewards" ? "menu-list active" : "menu-list"
            }
            to="/rewards"
          >
            {/* <img className="menu-icon" src={BannerIcon} /> 
            <RiMoneyCnyCircleLine />
            Rewards
          </Link>
        </li> */}
        {/* ) : null} */}
        {permissons?.includes("CAN-LIST-SCHEDULE RIDE") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "schedule-ride"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/schedule-ride"
            >
              {/* <img className="menu-icon" src={RequestIcon} /> */}
              <AiOutlineSchedule />
              Schedule ride
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-SUBSCRIPTIONS") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "subscription"
                  ? "menu-list active"
                  : splitLocation[1] === "subscription-add"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/subscription"
            >
              {/* <img className="menu-icon" src={NotifIcon} /> */}
              <MdOutlineSubscriptions />
              Subscription
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-PAY TAX") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "pay-tax"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/pay-tax"
            >
              {/* <img className="menu-icon" src={FaqIcon} /> */}
              <MdOutlinePayment />
              Pay Tax
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-SETTING") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "setting"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/setting"
            >
              {/* <img className="menu-icon" src={FaqIcon} /> */}
              <MdOutlineSettings />
              Setting
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-CANCEL LIST") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "cancelled"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/cancelled"
            >
              {/* <img className="menu-icon" src={FaqIcon} /> */}
              <CgList />
              Cancel List
            </Link>
          </li>
        ) : null}
        {/* {permissons?.includes("CAN-LIST-OFFLINE BOOOKING") ? ( */}
        {/* <li>
          <Link
            className={
              splitLocation[1] === "offline-booking"
                ? "menu-list active"
                : "menu-list"
            }
            to="/offline-booking"
          >
            {/* <img className="menu-icon" src={FaqIcon} /> 
            <GiNotebook />
            Offline Booking
          </Link>
        </li> */}
        {/* ) : null} */}
        {permissons?.includes("CAN-LIST-ACTIVITY LOG") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "activeLog"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/activeLog"
            >
              {/* <img className="menu-icon" src={FaqIcon} /> */}
              <TbFileTime />
              Activity Log
            </Link>
          </li>
        ) : null}
        {/* {permissons?.includes("CAN-LIST-REFER AND EARN") ? ( */}
        {/* <li>
          <Link
            className={
              splitLocation[1] === "refer-earn"
                ? "menu-list active"
                : "menu-list"
            }
            to="/refer-earn"
          >
            {/* <img className="menu-icon" src={FaqIcon} /> 
            <VscReferences />
            Refer and Earn
          </Link>
        </li> */}
        {/* ) : null} */}
        {permissons?.includes("CAN-LIST-NOTIFICATION") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "push-notification"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/push-notification"
            >
              {/* <img className="menu-icon" src={NotifIcon} /> */}
              <MdOutlineNotificationAdd />
              Push Notification
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-REVIEW AND RATING") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "review-rating"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/review-rating"
            >
              {/* <img className="menu-icon" src={FaqIcon} /> */}
              <MdOutlineStarRate />
              Review & Rating
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-HELP AND SUPPORTS") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "help-support"
                  ? "menu-list active"
                  : "menu-list"
              }
              to="/help-support"
            >
              {/* <img className="menu-icon" src={HelpIcon} /> */}
              <MdOutlineSupportAgent />
              Help & Support
            </Link>
          </li>
        ) : null}
        {permissons?.includes("CAN-LIST-REPORTS") ? (
          <li>
            <Link
              className={
                splitLocation[1] === "report" ? "menu-list active" : "menu-list"
              }
              to="/report"
            >
              {/* <img className="menu-icon" src={FaqIcon} /> */}
              <TbReport />
              Report
            </Link>
          </li>
        ) : null}
        <li>
          <Link
            className="menu-list"
            onClick={() => {
              Log();
            }}
          >
            {/* <img className="menu-icon" src={Logout} /> */}
            <RiLogoutCircleRLine />
            Logout
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
