import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../component/TopHeader";
import { hidePwdImg, showPwdImg } from "../../../assets/img";
import { ToastContainer, toast } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  useAdminAddMutation,
  useAdminEditMutation,
  useLazyAdminRolesQuery,
  useLazyAdminViewQuery,
  useLazyRideTypeViewQuery,
  useRideTypeAddMutation,
  useRideTypeEditMutation,
} from "../../../constants/redux/api/api";
import OtherCharge from "../Settings/otherCharge";
import Fare from "../Settings/fare";
import WaitingFare from "../Settings/waiting";
import PeakFare from "../Settings/peak";

const AddVehicle = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;
console.log(location,"loc");

  const [btn, setBtn] = useState(false);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [images, setImages] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [roleErr, setRoleErr] = useState(false);
  const [imagesErr, setImagesErr] = useState(false);

  // RTK QUERY
  const [rideTypeAddApi] = useRideTypeAddMutation();
  const [rideTypeEditApi] = useRideTypeEditMutation();
  const [rideTypeViewApi] = useLazyRideTypeViewQuery();

  const submitHandle = () => {
    let formData = new FormData();

    if (name?.length == 0 || images?.length == 0 || role?.length == 0) {
      setNameErr(true);
      setRoleErr(true);
      setImagesErr(true);
    } else {
      
      if (Id) {
        formData.append("name", name);
        formData.append("type", role);
        if (images?.name) {
          formData.append("icon", images);
        }
        // formData.append("_method", "PUT");
        setBtn(true);

        rideTypeEditApi({ id: Id, payload: formData })
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/vehicle-category");
            } else {
              console.log("res", res);
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      } else {
        setBtn(true);
        formData.append("name", name);
        formData.append("type", role);
  
        if (images?.name) {
          formData.append("icon", images);
        }
  
        rideTypeAddApi(formData)
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/vehicle-category");
            } else {
              console.log("res", res);
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  // VIEW API
  const viewRideType = () => {
    rideTypeViewApi(Id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setName(res?.rideType?.name);
          setRole(res?.rideType?.type);
          setImages(res?.rideType?.icon_url);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (Id) {
      viewRideType();
    }
  }, []);

  return (
    <>
      <aside>
        {type == "view" ? (
          <TopHeader type="view-vehicle" />
        ) : type == "edit" ? (
          <TopHeader type="edit-vehicle" />
        ) : (
          <TopHeader type="add-vehicle" />
        )}
        <article className="p-3">
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Name
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        placeholder="Name"
                        disabled={type == "view" ? true : false}
                      />
                      {name?.length == 0 && nameErr && (
                        <p className="err-p">Enter Name</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Type
                      </label>
                      <select
                        name="role"
                        id=""
                        onChange={(e) => setRole(e.target.value)}
                        value={role}
                        disabled={type == "view" ? true : false}
                      >
                        <option>Select Type</option>
                        <option value={1}>Auto</option>
                        <option value={2}>Car</option>
                      </select>
                      {role?.length == 0 && roleErr && (
                        <p className="err-p">Select Type</p>
                      )}
                    </div>
                  </div>
                  
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        ICON
                      </label>
                      <input
                        type={"file"}
                        onChange={(e) => setImages(e.target.files[0])}
                        accept="image/*"
                        disabled={type == "view" ? true : false}
                      />
                      {images?.length == 0 && imagesErr && (
                        <p className="err-p">Upload ICON</p>
                      )}
                    </div>
                  </div>
                </div>
                {images && (
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark d-none" for="">
                          ICON
                        </label>
                        <div className="d-flex justify-content-end">
                          <img
                            src={
                              images?.name
                                ? URL.createObjectURL(images)
                                : images
                            }
                            style={{ width: "40%", marginBottom: "20px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-auto ms-auto">
                  {type !== "view" && (
                    <button
                      type="button"
                      className="comn-btn bg-success-gradient px-3 py-2 me-2"
                      onClick={submitHandle}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </article>
      </aside>

      {Id && (
        <article>
          <div className="p-3">
            <Tabs
              defaultActiveKey="Detail-View"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="Detail-View" title="Base Fare">
                <OtherCharge Id={Id} type={type}/>
              </Tab>
              <Tab eventKey="Distance" title="Distance Fare">
                <Fare catType={1} Id={Id} type={type}/>
              </Tab>
              <Tab eventKey="Waiting" title="Waiting Fare">
                <WaitingFare Id={Id} type={type}/>
              </Tab>
              <Tab eventKey="Peak" title="Peak Fare">
                <PeakFare Id={Id} type={type}/>
              </Tab>
            </Tabs>
          </div>
        </article>
      )}
    </>
  );
};

export default AddVehicle;
