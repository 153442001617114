import React, { useEffect, useState } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import TopHeader from "../../../component/TopHeader";
import DriverLog from "./DriverLog";
import UserLog from "./UserLog";

const DriversActive = () => {
  return (
    <aside>
      <TopHeader type="active-log" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="tab-appoinment"
        >
          <Tab eventKey="Detail-View" title="Driver Log">
            <DriverLog />
          </Tab>
          {/* <Tab eventKey="Detail" title="User Log">
            <UserLog />
          </Tab> */}
        </Tabs>
      </article>
    </aside>
  );
};

export default DriversActive;
