import React, { useEffect, useState } from "react";
import TopHeader from "../../../component/TopHeader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Reject from "./reject";
import Request from "./request";

const DriverRequest = () => {
  const [reject, setReject] = useState([]);
  const [request, setRequest] = useState([]);

  const getList = () => {};

  // useEffect(() => {
  //   getList();
  // }, []);

  return (
    <aside>
      <TopHeader type="driver-request" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Detail-View" title="Request List">
            <Request />
          </Tab>
          <Tab eventKey="Detail" title="Rejected List">
            <Reject />
          </Tab>
        </Tabs>
      </article>
    </aside>
  );
};

export default DriverRequest;
