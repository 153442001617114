import React, { useState, useEffect } from "react";
import { Col, Form, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../component/UI/input";
import {
  hidePwdImg,
  LoginLeft,
  Logo,
  LogoIn,
  showPwdImg,
} from "../../assets/img";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import useToken from "../../constants/Storage/useToken";
import useUser from "../../constants/Storage/userData";
import { useLoginMutation } from "../../constants/redux/api/api";
import { getToken, onMessage } from "firebase/messaging";
import { getPermission, messaging } from "../../firebase/firebaseConfig";
import Message from "../../component/Message";
import addNotification from "react-push-notification";
import Swal from 'sweetalert2';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [nameErr, setnameErr] = useState(false);
  const [passErr, setpassErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);

  const [LoginAPi] = useLoginMutation();

  const submitHandle = () => {
    if (name?.length == 0 || pass?.length < 8) {
      setnameErr(true);
      setpassErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("username", name);
      formdata.append("password", pass);
      if (localStorage.getItem("fcm_id")) {
        formdata.append("fcm_id", localStorage.getItem("fcm_id"));
      }
      LoginAPi(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            setBtn(false);
            setToken(res?.token);
            setUser(res?.admin);
            // localStorage.setItem("token", res?.token);
            localStorage.setItem("role", JSON.stringify(res?.permissions));
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            getPermission();

            setTimeout(() => {
              navigate("/");
              // window.location.reload();
            }, 800);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  // //
  // const vapidKey = process.env.REACT_APP_VAPID_KEY;
  // console.log(vapidKey, "new");

  // const generateToken = async () => {
  //   try {
  //     const token = await getToken(messaging, {
  //       vapidKey:vapidKey

  //     });

  //     localStorage.setItem("fcm_id", token);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };
  // async function requestPermission() {

  //   const permission = await Notification.requestPermission();

  //   if (permission === "granted") {
  //     const token = await getToken(messaging, {
  //       vapidKey: vapidKey,
  //     });
  //     console.log("Token generated : ", token);
  //     // toast.success("welcome to the site", {
  //     //   position: "top-right",
  //     //   closeOnClick: true,
  //     //   pauseOnHover: true,
  //     //   draggable: true,
  //     //   progress: undefined,
  //     //   autoClose: 2000,
  //     // });
  //     alert("welcome to the site");
  //   } else if (permission === "denied") {
  //     // toast.success("You denied for the notification", {
  //     //   position: "top-right",
  //     //   closeOnClick: true,
  //     //   pauseOnHover: true,
  //     //   draggable: true,
  //     //   progress: undefined,
  //     //   autoClose: 2000,
  //     // });
  //     await Notification.requestPermission()
  //     alert("You denied for the notification");
  //   }
  // }
  useEffect(() => {
    if (Notification.permission == "denied") {
      try {
        Swal.fire({
          title: 'Allow Notification',
          text:  "To enable notifications, please go to your browser's settings, find the notification permissions for this site, and allow notifications.",
          // icon: logi, // You can also use 'success', 'error', 'warning', 'question'
          confirmButtonText: 'OK',
        });
       
      } catch (error) {
        console.log("Failed to show notification:", error);
      }
    }
  }, []);
  // onMessage(messaging, (payload) => {
  //   console.log("incoming msg",payload);
  //   toast(<Message notification={payload.notification} />);
  // });
  return (
    <Col xs={12} className="d-flex vh-100 log-in">
      <Col xs={12} md={6} className="left-log-in bg-primary">
        <div className="log-in-left">
          {/* <Image className="" src={LoginLeft} /> */}
        </div>
      </Col>
      <Col xs={12} md={6} className="vh-100 flex-ac-jc bg-background1">
        <div className="bg-background rounded-3 px-4 py-5 w-75 login-box">
          <div className="flex-ac-jc">
            <Image className="log-logo me-2" src={""} />
          </div>
          <h4 className="sign-in-text txt-primary4 mb-0 mt-0">Sign In</h4>
          <div class="px-0 col-sm-12 modal-label-input">
            <label class="text-primary-dark" for="">
              User Name
            </label>
            <input
              type="Name"
              id="example-if-email"
              name="name"
              onChange={(e) => setName(e.target.value)}
              placeholder="username"
              className="px-2 mt-1"
              value={name}
              autocomplete="off"
            />
            {name?.length == 0 && nameErr && (
              <span className="err">Enter the Username</span>
            )}
          </div>
          <div class="px-0 col-sm-12 modal-label-input">
            <label class="text-primary-dark" for="">
              Password
            </label>
            <div className="pwd-container">
              <input
                type={isRevealPwd ? "text" : "password"}
                name="password"
                onChange={(e) => setPass(e.target.value)}
                placeholder="******"
                className="px-2 mt-1"
                value={pass}
                autocomplete="off"
              />
              <img
                src={isRevealPwd ? showPwdImg : hidePwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            </div>
            {pass?.length < 8 && passErr && (
              <span className="err">Enter 8 digit Password</span>
            )}
          </div>

          {/* <Link
              to="/forgot-password"
              className="d-flex flex-row-reverse"
              style={{ fontSize: "14px", marginTop: "2px" }}
            >
              Forgot Password
            </Link> */}
          <div className="d-flex">
            <button
              onClick={submitHandle}
              className="comn-btn border-0 px-3 py-2 bg-primary1 text-white rounded-1 text-decoration-none me-2"
              disabled={btn ? true : false}
            >
              Login
            </button>
          </div>
        </div>
      </Col>
    </Col>
  );
};

export default SignIn;
