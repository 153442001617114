import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { bannerList, maleAvatar } from "../../../assets/img";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";

import { Row, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import swal from "sweetalert";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useLazyActivityLogViewFilterQuery, useLazyActivityLogViewQuery } from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

let active = 3;
let items = [];
for (let number = 1; number <= 4; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}

function ActiveLogList() {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const detail = location?.state?.data;

  //RTK
  const [activityLogFilterApi] = useLazyActivityLogViewFilterQuery();
  const [activityLogApi] = useLazyActivityLogViewQuery();

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ value, row }) => {
          return <span>{moment(value).format("DD/MM/YYYY")}</span>;
        },
      },
      {
        Header: "Logged In Hours",
        accessor: "total_hours",
        Cell: ({ value, row }) => {
          var hours = value / 60;
          var rhours = Math.floor(hours);
          var minutes = (hours - rhours) * 60;
          var rminutes = Math.round(minutes);
          return <span>{rhours + " hrs " + rminutes + " min"}</span>;
        },
      },

      // {
      //   Header: "Rides Received",
      //   accessor: "received_rides",
      //   Cell: ({ value, row }) => {
      //     return <>{value}</>;
      //   },
      // },
      {
        Header: "Rides Completed",
        accessor: "completed_rides",
        Cell: ({ value, row }) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: "Amount Earned",
        accessor: "earned_amount",
        Cell: ({ value, row }) => {
          return (
            <span>{value !== null ? parseInt(value).toFixed(2) : ""}</span>
          );
        },
      },
    ],
    []
  );

  const [list, setList] = useState([]);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  const getList = () => {
    let params = `?start_date=${from}&end_date=${to}`;
    if (from && to) {
      activityLogFilterApi(Id, params).then((res) => {
        setList(res?.data?.driver_log);
        console.log(res?.data?.driver_log)
      }).catch(err=>console.log(ErrorEvent))
    } else if (!from && !to) {
      activityLogApi(Id).then((res) => {
        setList(res?.data?.driver_log);
        console.log(res?.data?.driver_log)
      }).catch(err=>console.log(err))
    }
  };

  useEffect(() => {
    if (Id) {
      getList();
    }
  }, [from, to]);


  return (
    <>
      <aside>
        <TopHeader type="activity-view" />
        <article className="p-3">
          <article>
            <div className="p-3">
              <div className="p-3 bg-white rounded-2">
                <div className="flex-ac-jb">
                  {/* <p
                    className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                    for=""
                  ></p>
                  <img className="avatar-img" src={maleAvatar} /> */}
                  <p
                    className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                    for=""
                  ></p>
                  <img
                    className="avatar-img"
                    src={detail?.driver_detail?.img_url || maleAvatar}
                  />
                </div>
                <Form className="d-flex flex-wrap">
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Driver Name
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder="Phone Number"
                          className="bg-transparent"
                          type="text"
                          style={{
                            cursor: "pointer",
                            "text-decoration-line": "underline",
                            background: "none",
                            color: "var(--bs-link-color)",
                          }}
                          value={detail?.name}
                          readOnly
                          onClick={() =>
                            navigate("/driver/view-profile", {
                              state: { id: detail?.id },
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Driver ID
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder="Phone Number"
                          className="bg-transparent"
                          type="text"
                          value={"#00" + detail?.id}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Phone Number
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder="Phone Number"
                          className="bg-transparent"
                          type="text"
                          value={detail?.mobile}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Account Status
                        </label>
                        <input
                          id="example-if-email"
                          name="Phone_Number"
                          placeholder="Phone Number"
                          className="bg-transparent"
                          type="text"
                          value={
                            detail?.current_status == 4
                              ? "Payment Pending"
                              : detail?.current_status == 5
                              ? "Subscription Ended"
                              : detail?.current_status == 6
                              ? "Disable"
                              : "Active"
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </article>
        </article>
      </aside>
      <aside>
        {/* <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
            <Col md={4} xs={6} className="px-2 ">
              <span>From Date</span>
              <input
                type="date"
                className="form-control"
                onChange={startDate}
                value={from}
              />
            </Col>
            <Col md={4} xs={6} className="px-2 ">
              <span>To Date</span>
              <input
                type="date"
                className="form-control"
                onChange={ToDate}
                value={to}
              />
            </Col>
            {/* <Col md={4} xs={6} className="px-2 my-2">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1 form-control"
              />
            </Form>
          </Col> 
          </div>
          
            <table className="custom-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Logged In Hours</th>
                  <th>Rides Received</th>
                  <th>Rides Completed</th>
                  <th>Amount Earned</th>
                  {/* <th>Active Location</th> 
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((list, index) => {
                  var num = list?.total_hours;
                  var hours = num / 60;
                  var rhours = Math.floor(hours);
                  var minutes = (hours - rhours) * 60;
                  var rminutes = Math.round(minutes);
                  return (
                    <tr key={index}>
                      <td>
                        {index == 9
                          ? last
                          : counter
                          ? `${counter}${index + 1}`
                          : index + 1}
                      </td>
                      <td>{list?.date}</td>
                      <td>{rhours + " hrs " + rminutes + " min"}</td>
                      <td>{list?.received_rides}</td>
                      <td>{list?.completed_rides}</td>
                      <td>{Math.round(list?.earned_amount)}</td>
                      {/* <td>{"vadapalani"}</td> 
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </article> */}

        <article className="p-3">
          <div className="p-2 bg-white rounded-2">
            {/* <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
              <Col md={3} xs={6} className="px-2 my-2">
                <span>From Date</span>
                <input
                  type="date"
                  className="form-control"
                  onChange={startDate}
                  value={from}
                />
              </Col>
              <Col md={3} xs={6} className="px-2 my-2">
                <span>To Date</span>
                <input
                  type="date"
                  className="form-control"
                  onChange={ToDate}
                  value={to}
                />
              </Col>
            </div> */}
            <Table columns={columns} data={list} />
          </div>
        </article>
      </aside>
    </>
  );
}

export default ActiveLogList;
