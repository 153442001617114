import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment/moment";
import {
  useLazyEditSubscriptionQuery,
  useSubscriptionPaymentMutation,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

const Subscribtion_Detailed_View = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const permissons = JSON.parse(localStorage.getItem("role"));
  const [paymentDetail, setPaymentDetail] = useState();
  const [editModal, setEditModal] = useState(false);
  const [subscribeId, setSubcribeId] = useState();
  //RTK
  const [subscriptionPaymentApi] = useSubscriptionPaymentMutation();
  const [editSubscriptionApi] = useLazyEditSubscriptionQuery();

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "User Name",
        accessor: "driver.name",
        Cell: ({ value, row }) => {
          return (
            <span
              style={{
                cursor: "pointer",
                "text-decoration-line": "underline",
                background: "none",
                color: "var(--bs-link-color)",
              }}
              onClick={() =>
                navigate("/driver/view-profile", {
                  state: { id: row.original.driver.id },
                })
              }
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Mobile",
        accessor: "driver.mobile",
        Cell: ({ value, row }) => {
          return <span>{value}</span>;
        },
      },

      {
        Header: "Subscription Purchase Date",
        accessor: "start_date",
        Cell: ({ value, row }) => {
          return (
            <>{value != null ? moment(value).format("DD/MM/YYYY") : " "}</>
          );
        },
      },
      {
        Header: "Subscription Expiry Date",
        accessor: "end_date",
        Cell: ({ value, row }) => {
          return (
            <>{value != null ? moment(value).format("DD/MM/YYYY") : " "}</>
          );
        },
      },

      {
        Header: "Payment details and Time Stamp",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <button
                className="comn-btn bg-primary edit-btn-svg"
                onClick={() => paymentData(row.original)}
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const [list, setList] = useState([]);
  const getList = () => {
    editSubscriptionApi(Id)
      .unwrap()
      .then((res) => {
        setList(res?.driver_subscriptions);
        setSubcribeId(res?.subscription);
      });
  };

  console.log(subscribeId?.id);

  const paymentData = (data) => {
    setEditModal(true);
    let driverId = {
      driver_id: data?.driver_id,
      subscription_id: Id,
    };
    subscriptionPaymentApi(driverId)
      .unwrap()
      .then((res) => {
        setPaymentDetail(res?.payment_detail);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (Id) {
      getList();
    }
  }, []);

  return (
    <aside>
      <TopHeader type="detail-view" />
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <Table columns={columns} data={list} />
        </div>
      </article>
      <Modal
        className="table-modal"
        show={editModal}
        onHide={() => setEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Payment details</h4>
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Razorpay
            </label>
            <input
              type="text"
              id="example-if-email"
              name="Razorpay"
              placeholder="Razorpay"
              value={paymentDetail?.rz_order_id}
              disabled
            />
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Date and Time
            </label>
            <input
              type="text"
              id="example-if-email"
              name="Date-and-Time"
              placeholder="Date and Time"
              value={
                paymentDetail?.created_at
                  ? moment(paymentDetail?.created_at).format(
                      "DD/MM/YYYY hh:mm A"
                    )
                  : " "
              }
              disabled
            />
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Amount
            </label>
            <input
              type="text"
              id="example-if-email"
              name="Amount"
              placeholder="Status"
              value={paymentDetail?.amount}
              disabled
            />
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Status
            </label>
            <input
              type="text"
              id="example-if-email"
              name="Status"
              placeholder="Status"
              className={
                paymentDetail?.status == 1 ? "text-success" : "text-danger"
              }
              value={paymentDetail?.status == 1 ? "Success" : "Failed"}
              disabled
            />
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={() => setEditModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default Subscribtion_Detailed_View;
