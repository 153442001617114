import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import Swal from "sweetalert2";
import { GlobalFilter } from "./GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import {
  useLazyRoleListQuery,
  useLazyRoleStatusQuery,
  useRoleDeleteMutation,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const data = [
  {
    id: 1,
    role: "Super Admin",
    status: "Active",
  },
  {
    id: 2,
    role: "Admin",
    status: "Active",
  },
  {
    id: 3,
    role: "Super Admin",
    status: "Active",
  },
  {
    id: 4,
    role: "Test Admin",
    status: "DeActive",
  },
];

const RolesTest = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Name",
        accessor: "role",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <button
              onClick={() => {
                statusChange(row.original.id);
              }}
              className={
                value == 1
                  ? "p-2 py-1 comn-btn active-deact-btn bg-success"
                  : "p-2 py-1 comn-btn active-deact-btn bg-danger"
              }
              disabled={btn ? true : false}
            >
              {value == 1 ? "Active" : "Deactive"}
            </button>
          );
        },
      },

      // {
      //   Header: "Detail View",
      //   accessor: "acti",
      //   Cell: ({ value, row }) => {
      //     return (
      //       <React.Fragment>
      //         <div className="flex-ac-tr text-center">
      //           {/* {permissons?.includes("CAN-VIEW-ROLES") ? ( */}
      //           <button
      //             onClick={() =>
      //               navigate("/roles/add-roles", {
      //                 state: {
      //                   id: row.original.id,
      //                   type: "view",
      //                 },
      //               })
      //             }
      //             className="comn-btn bg-primary edit-btn-svg"
      //           >
      //             <FontAwesomeIcon icon={faEye} />
      //           </button>
      //           {/* ) : null} */}
      //           {/* {permissons?.includes("CAN-EDIT-ROLES") ? ( */}
      //           {/* <button
      //             onClick={() =>
      //               navigate("/roles/add-roles", {
      //                 state: {
      //                   id: row.original.id,
      //                   type: "edit",
      //                 },
      //               })
      //             }
      //             className="comn-btn bg-primary edit-btn-svg"
      //           >
      //             <FontAwesomeIcon icon={faPen} />
      //           </button> */}
      //           {/* ) : null} */}
      //           {/* <button
      //           className="comn-btn bg-danger edit-btn-svg"
      //           onClick={() => deleteHandle(row.original.id)}
      //         >
      //           <FontAwesomeIcon icon={faTrash} />
      //         </button> */}
      //         </div>
      //       </React.Fragment>
      //     );
      //   },
      // },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="flex-ac-tr text-center">
                {/* {permissons?.includes("CAN-VIEW-ROLES") ? ( */}
                <button
                  onClick={() =>
                    navigate("/roles/add-roles", {
                      state: {
                        id: row.original.id,
                        type: "view",
                      },
                    })
                  }
                  className="comn-btn bg-primary edit-btn-svg"
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
                {/* ) : null} */}
                {/* {permissons?.includes("CAN-EDIT-ROLES") ? ( */}
                <button
                  onClick={() =>
                    navigate("/roles/add-roles", {
                      state: {
                        id: row.original.id,
                        type: "edit",
                      },
                    })
                  }
                  className="comn-btn bg-primary edit-btn-svg"
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
                {/* ) : null} */}
                <button
                  className="comn-btn bg-danger edit-btn-svg"
                  onClick={() => deleteHandle(row.original.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  // RTK QUERY
  const [roleApi] = useLazyRoleListQuery();
  const [roleStatusApi] = useLazyRoleStatusQuery();
  const [deleteApi] = useRoleDeleteMutation();

  const [btn, setBtn] = useState(false);
  const [list, setList] = useState([]);

  const getRole = () => {
    roleApi()
      .unwrap()
      .then((res) => {
        setList(res?.roles);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const statusChange = (id) => {
    setBtn(true);
    roleStatusApi(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          getRole();
        } else {
          setBtn(false);
        }
      });
  };
  const deleteHandle = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteApi(id)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              getRole();
            }
          });
      }
    });
  };
  useEffect(() => {
    getRole();
  }, []);

  return (
    <aside>
      <TopHeader type="role" />
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          {/* <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end"> */}
            {/* <div className="flex-ac-jc"> */}
              {permissons?.includes("CAN-ADD-ROLES") ? (
                <Link
                  to="/roles/add-roles"
                  className="comn-btn bg-primary1 px-3 py-2 ad-rol-a"
                  style={{
                    width: "120px",
                    margin: "20px 0 10px auto",
                    display: "block",
                  }}
                >
                  Add Roles
                </Link>
              ) : null}
            {/* </div> */}
          {/* </div> */}
          <Table columns={columns} data={list} />
        </div>
      </article>
    </aside>
  );
};

export default RolesTest;
