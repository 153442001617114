import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form, Tab, Tabs } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";
import {
    useLazyRideNotifiViewQuery
} from "../../../constants/redux/api/api";
import { useDispatch } from "react-redux";


function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

const UserNotification = () => {
  const location = useLocation();
  const Id = location?.state?.data?.id;
  const Type = location?.state?.type;
  console.log(Type);
  
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [driver, setDriver] = useState([]);
  const [accept,setAccept]=useState([])

  //RTK
  const [userView] = useLazyRideNotifiViewQuery();

  const apiUser = () => {
    userView(Id)
      .unwrap()
      .then((res) => {
        console.log(res);
        // setUserList(res?.active_users);
        setList(res?.user_notifications);
        setDriver(res?.driver_notifications);
        setAccept(res?.before_accept_ride_notifications)
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    if (Id) {
      apiUser();
    }
  }, []);
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Ride Id",
        accessor: "ride_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Notification Id",
        accessor: "notifiable_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Tittle",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Notification",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
    ],
    []
  );
  const columns2 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Ride Id",
        accessor: "ride_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Notification Id",
        accessor: "notifiable_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Notification",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
    ],
    []
  );
  const columns3 = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      // {
      //   Header: "Ride Id",
      //   accessor: "ride_id",
      //   Cell: ({ value, row }) => {
      //     return <>{value}</>;
      //   },
      // },

      {
        Header: "Notification Id",
        accessor: "notifiable_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Notification",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
    ],
    []
  );

  return (
    <aside>
      <TopHeader type="push-notification" />
      <article className="p-3">
        {Type == "ride" && (
          <Tabs
            defaultActiveKey="Detail"
            id="uncontrolled-tab-example"
            className="mb-3"
            // onSelect={(e) => setActive(e)}
            fill
          >
            <Tab eventKey="Detail" title="user">
              <div className="p-2 bg-white rounded-2">
                <Table columns={columns} data={list} />
              </div>
            </Tab>
            <Tab eventKey="Vechile" title="Driver">
              <div className="p-2 bg-white rounded-2">
                <Table columns={columns2} data={driver} />
              </div>
            </Tab>
          </Tabs>
        )}
        {Type == "search" && (
              <div className="p-2 bg-white rounded-2">
                <Table columns={columns3} data={accept} />
              </div>
          // <Tabs
          //   defaultActiveKey="Before Accept Notification"
          //   id="uncontrolled-tab-example"
          //   className="mb-3"
          //   // onSelect={(e) => setActive(e)}
          //   fill
          // >
            
          //   {/* <Tab eventKey="Vechile" title="Before Accept Notification"> */}
          //   {/* </Tab> */}
          // </Tabs>
        )}
      </article>
    </aside>
  );
};

export default UserNotification;
