import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Tab, Tabs } from "react-bootstrap";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Booking from "./Booking";
import BookingCompleted from "./BookingCompleted";

const BookingList = () => {
  return (
    <aside>
      <TopHeader type="booking" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="pending"
          id="uncontrolled-tab-example"
          className="tab-appoinment w-50 m-3"
          fill
        >
          <Tab eventKey="pending" title="Booking List">
            <Booking />
          </Tab>

          <Tab eventKey="resolved" title="Booking Completed">
            <BookingCompleted />
          </Tab>
        </Tabs>
      </article>
    </aside>
  );
};

export default BookingList;
