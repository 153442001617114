import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReactPaginate from "react-paginate";
import Fair from "./fair";
import Zone from "./zone";
import FareSetting from "./fareSetting";
import Tax from "./tax";

function Setting() {
  return (
    <>
      <aside>
        <TopHeader type="setting" />
        <article>
          <div className="p-3">
            <Tabs
              defaultActiveKey="Detail"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {/* <Tab eventKey="Detail-View" title="Fair setting">
                <Fair />
              </Tab> */}
              {/* <Tab eventKey="Detail-View" title="Fare Setting">
                <FareSetting />
              </Tab> */}
              <Tab eventKey="Detail" title="Location Setting">
                <Zone />
              </Tab>
              <Tab eventKey="Tax" title="Setting">
                <Tax />
              </Tab>
            </Tabs>
          </div>
        </article>
      </aside>
    </>
  );
}

export default Setting;
