import React from "react";
import { BrowserRouter, HashRouter as Router } from "react-router-dom";
import { ReactRoute } from "./routes";
import { BallTriangle, LineWave, Oval } from "react-loader-spinner";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useToken from "./constants/Storage/useToken";
import { useDispatch, useSelector } from "react-redux";
import { saveAlertMessage } from "./constants/redux/slice/alertMessage";
import ErrorPopup from "./constants/Storage/ErrorPopup";
import { onMessage } from "firebase/messaging";
import { getPermission, messaging } from "./firebase/firebaseConfig";
import { logIn2, LogoIn } from "./assets/img";
import addNotification, { Notifications } from "react-push-notification";


function App() {
  const { token } = useToken();
  const dispatch = useDispatch();
  const alertMessage = useSelector((state) => state.alertMessage);
  const [loading, setLoading] = useState(false);

  // For Loader
  const isSomeQueryPending = useSelector((state) =>
    Object.values(state.api.queries).some((query) => query.status === "pending")
  );

  useEffect(() => {
    if (isSomeQueryPending) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [isSomeQueryPending]);

  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "9999",
  };

  const onCloseClick = () => {
    dispatch(saveAlertMessage(null));
  };
  useEffect(() => {
    // var notifications = 2;
    // document.title = "(" + notifications + ") ...";

    // (async () => {
    //   const hasFirebaseMessagingSupport = await isSupported();
    //   if (hasFirebaseMessagingSupport) {
    //     await getPermission();
    //   }
    // })();

    try {
      getPermission();
      onMessage(messaging, (payload) => {
        // toast.success(payload.notification.title, {
        //   position: "top-right",
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   autoClose: 5000,
        // });
       
        addNotification({
          title: payload.notification.title,
          message: payload.notification.message,
          duration: 5000,
          icon: logIn2,
          native: true,
        });
        console.log("payload", payload);
      });
    } catch (error) {
      console.log("error", error);
    }
    const browserDetails = {
      appName: window.navigator.appName,
      appVersion: window.navigator.appVersion,
      userAgent: window.navigator.userAgent,
      platform: window.navigator.platform,
      language: window.navigator.language,
      react :React.version
    };
    console.log('Browser details:', browserDetails);
  }, []);

  
  return (
    <Router>
      {alertMessage && (
        <ErrorPopup alertMessage={alertMessage} onCloseClick={onCloseClick} />
      )}
      <BallTriangle
        height={100}
        width={100}
        radius={5}
        color="#7b2cbf"
        ariaLabel="loading"
        wrapperStyle={style}
        wrapperClass
        visible={loading}
      />
      <ToastContainer autoClose={false} theme={"colored"} hideProgressBar />
      <ReactRoute />
      <Notifications/>
    </Router>
  );
}

export default App;
