// export const BASE_URL = "https://scriptbhyto.premiumfreshfoods.com/crmapi/"; // LIVE URL
// export const BASE_URL = "https://bhytoscript.ninositsolution.com/crmapi/"; // LIVE URL
export const BASE_URL = "https://testing.bhytoscript.primenexusmfs.com/crmapi/"; // LIVE URL NEW
// export const BASE_URL ="http://192.168.1.11/bhyto_taxi_backend_script/public/crmapi/"; // LOCAL

// https://testing.bhytoscript.primenexusmfs.com/

export const URL = {
  // LOGIN
  LOGIN: "admin/login",
  LOGOUT: "admin-logout",
  CHANGE_PASSWORD: "admin-change-password",

  // DASHBOARD

  DASHBOARD: "counts",
  DASHBOARD_PIE: "count-percentage",
  DASHBOARD_REVENUE: "revenue-graph",
  DASHBOARD_PAY_TAX: "pay-tax-graph",

  // ROLES
  ROLE: "role",

  // ADMIN
  ADMIN: "admin",
  ADMIN_ROLES: "active-roles",

  // SUBSCRIPTIONS

  SUBSCRIPTION: "subscription",
  SUBSCRIPTION_PAYMENT: "subscription-payment-details",

  // ZONES
  ZONE: "zone",
  IMPORT_ZONE: "import-zone",
  EXAMPLE_ZONE: "export-sample-zone",

  // FARE
  FARE: "price",
  FARE_ESTIMATION: "ride-fare-detail",

  // FARE -  PEAK
  PEAK: "peak-charge",

  // FARE - OTHER CHARGES
  OTHERCHARGES: "other-charge",

  // SETTING

  SETTING: "setting",

  // DRIVER REQUEST

  DRIVER_REQUSET: "driver-request",
  DRIVER_REQUSET_REJECT: "request-reject",
  PROOF_ACCEPT: "proof-approve",


  // DRIVER

  DRIVERS: "accepted-drivers",
  DRIVER_STATUS: "driver-status/",
  DRIVER_VIEW: "driver-profile/",
  DRIVER_RIDE_HISTORY: "driver-ride-history/",
  DRIVER_RIDE_HISTORY_VIEW: "driver-ride-history-view",
  DRIVER_SUBSCRIPTION_DATE: "change-subscription-end-date",
  
  // USERS

  USERS: "users-list",
  USERS_VIEW: "user-view/",
  USER_STATUS: "user-status/",
  USER_RIDE_HISTORY: "user-ride-history/",
  // USER_RIDE_HISTORY_VIEW: "ride-history-view/",
  USER_RIDE_HISTORY_VIEW: "user-ride-history-view/",

  // HELP & SUPPORT

  HELP_SUPPORT_PENDING: "supports-raised",
  HELP_SUPPORT_RESOLVED: "supports-resolved ",
  REPLY_SUPPORT: "support-reply",

  // OFFLINE BOOKING

  OFFLINE_BOOKING: "offline-prebooking",
  OFFLINE_FIRST_SEARCH: "offline-first-search",
  OFFLINE_USER_LIST: "user-list",
  OFFLINE_BOOKING_LIST: "offline-booking-list",
  OFFLINE_SECOND_SEARCH: "offline-second-search",
  OFFLINE_BOOKING_STATUS: "search-ride-status",
  CANCEL_RIDE: "cancel-search-ride",

  // REWARDS

  REQUEST_REWARD: "request-reward-list",
  REQUEST_REWARD_DETAILS: "request-reward-detail",
  REWARD_PAY: "reward-paid",
  REWARD_HISTORY: "reward-history",

  // PAY TAX

  PAY_TAX_LIST: "pay-tax-list",

  // BOOKING

  BOOKING_LIST: "bookings",
  BOOKING_VIEW: "booking-view/",

  // SCHEDULED BOOKING

  SCHEDULED_BOOKING: "pre-bookings",

  // REFFERALLS

  REFFERAL: "refferals",

  // CANCEL && REJECTERD  && MISSED OUT

  CANCEL_REJECT_MISSED: "cancel-ride",

  REJECT_RIDE_VIEW: "reject-ride-view/",

  CANCEL_RIDE_VIEW: "cancel-ride-view/",

  // REVIEW

  USER_REVIEW: "user-reviews",

  DRIVER_REVIEW: "driver-reviews",

  // ACTIVITY LOG

  ACTIVITY_LOG: "activity-log",

  // REPORTS

  REPORTS: "report",
  EXCEL: "export",

  // NOTIFICATION

  READ_STATUS: "notification-read-status",
  READ_ENTRY: "notification-read-entry",
  NOTIFY_LIST: "all-notifications",
  RIDE_NOTIFICATION:"ride-notifications",
  RIDE_NOTIFICATION_VIEW:"notifications/",
  RIDE_SEARCH_NOTIFICTION:"search-ride-notifications/",
  
  // PUSH NOTIFICATION
  
  PUSH_NOTIFY: "d-notification",

  // PDF COMPLETED RIDE

  COMPLETED_PDF: "completed-rides-pdf",

  COMPLETED_RIDES: "completed-rides",
   // /RIDE TYPE 
  RIDE_TYPE: "ride-type", 
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";
export const ERROR_STATUS = "error_status";
export const MAPS_API_KEY = "AIzaSyAiQyPFcrisIDlCqjPxWdnhxXPu1Ig1FHY";

export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
